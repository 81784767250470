.ourAdvantagesSection {
  background-color: #ebedee;
}

.ourAdvantagesSection__container {
  max-width: 980px;
  display: flex;
  flex-direction: column;
  padding-top: 67px;
  padding-left: 130px;
  padding-bottom: 35px;
}

.ourAdvantagesSection__title {
  margin: 0;
  max-width: 500px;
  color: #34373a;
  font-weight: 500;
  font-size: 64px;
  line-height: 1.1;
}

.ourAdvantagesSection__text {
  margin: 0;
  max-width: 480px;
  color: #34373a;
  font-weight: 400;
  font-size: 24px;
  align-self: flex-end;
}

.ourAdvantagesSection__cards-container {
  background-image: url(../../img/ourAdvantagesSection-bg-img.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 100px;
  padding-bottom: 150px;
  display: flex;
  justify-content: center;
}

.ourAdvantagesSection__cards {
  display: flex;
  flex-wrap: wrap;
  max-width: 1180px;
  gap: 120px 20px;
}

.ourAdvantagesSection__card {
  width: 380px;
  height: 280px;
  background-image: url(../../img/ourAdvantagesSection-card-bg-img.png);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.ourAdvantagesSection__card-ico {
  position: absolute;
  top: 20%;
  left: 38%;
}

.ourAdvantagesSection__card-title {
  margin: 0;
  max-width: 246px;
  color: #34373a;
  font-weight: 500;
  font-size: 24px;
  text-transform: uppercase;
  text-align: right;
  position: absolute;
  bottom: 69px;
  right: 24px;
}

.ourAdvantagesSection__card-texts {
  margin: 0;
  max-width: 295px;
  color: #34373a;
  font-weight: 400;
  font-size: 18px;
  text-align: right;
  position: absolute;
  bottom: 27px;
  right: 24px;
}

@media (max-width: 1270px) {
  .ourAdvantagesSection__container {
    max-width: 650px;
    padding-top: 60px;
    padding-left: 59px;
    padding-bottom: 37px;
  }
  .ourAdvantagesSection__title {
    max-width: 320px;
    font-size: 36px;
  }
  .ourAdvantagesSection__text {
    max-width: 430px;
    font-size: 16px;
  }
  .ourAdvantagesSection__cards-container {
    padding-top: 21px;
    padding-bottom: 44px;
  }
  .ourAdvantagesSection__cards {
    max-width: 650px;
    gap: 50px 10px;
  }
  .ourAdvantagesSection__card {
    width: 210px;
    height: 170px;
  }
  .ourAdvantagesSection__card-ico {
    width: 63px;
    height: 63px;
  }
  .ourAdvantagesSection__card-title {
    max-width: 151px;
    font-size: 14.82px;
    bottom: 39px;
    right: 12px;
  }
  .ourAdvantagesSection__card-texts {
    max-width: 168px;
    font-size: 11px;
    bottom: 18px;
    right: 13px;
  }
}

@media (max-width: 755px) {
  .ourAdvantagesSection__container {
    max-width: 305px;
    padding-top: 40px;
    padding-left: 35px;
    padding-bottom: 12px;
  }
  .ourAdvantagesSection__title {
    max-width: 200px;
    font-size: 20px;
  }
  .ourAdvantagesSection__text {
    max-width: 200px;
    font-size: 10px;
  }
  .ourAdvantagesSection__cards {
    max-width: 210px;
    gap: 10px 10px;
  }
}

@media (max-width: 374px) {
  .ourAdvantagesSection__texts {
    max-width: 185px;
  }
}
