.prosSection__top-part {
  max-width: 1180px;
  display: flex;
  flex-direction: column;
  padding-top: 69px;
  padding-left: 130px;
  padding-bottom: 30px;
}

.prosSection__top-part-title {
  margin: 0;
  max-width: 500px;
  color: #34373a;
  font-weight: 500;
  font-size: 64px;
  line-height: 1.1;
}

.prosSection__top-part-text {
  margin: 0;
  max-width: 680px;
  color: #34373a;
  font-weight: 400;
  font-size: 24px;
  align-self: flex-end;
  padding-top: 10px;
}

.prosSection__top-part-text:first-of-type {
  padding-top: 0;
}

.prosSection__bottom-part {
  min-height: 1025px;
  background-image: url(../../img/prosSection-bg-img.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.prosSection__bottom-part-bgFigureImg {
  position: absolute;
  left: 0;
  bottom: 0;
}

.prosSection__bottom-part-BgLogoImg {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.prosSection__bottom-part-list {
  position: absolute;
  left: 130px;
  bottom: 130px;
  z-index: 2;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.prosSection__bottom-part-list-item-container {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  gap: 15px;
}

.prosSection__bottom-part-list-item-container:last-of-type {
  margin-bottom: 0;
}

.prosSection__bottom-part-list-item {
  max-width: 579px;
  color: #ebedee;
  font-weight: 400;
  font-size: 32px;
}

@media (max-width: 1279px) {
  .prosSection__top-part {
    max-width: 583px;
    padding-top: 60px;
    padding-left: 60px;
    padding-bottom: 10px;
  }
  .prosSection__top-part-title {
    max-width: 210px;
    font-size: 36px;
  }
  .prosSection__top-part-text {
    max-width: 373px;
    font-size: 16px;
  }
  .prosSection__bottom-part {
    min-height: 645px;
    background-position: right;
  }
  .prosSection__bottom-part-bgFigureImg {
    width: 453px;
    height: 591px;
  }
  .prosSection__bottom-part-BgLogoImg {
    width: 530px;
    height: 447px;
    left: -146px;
  }
  .prosSection__bottom-part-list {
    left: 70px;
    bottom: 79px;
  }
  .prosSection__bottom-part-list-item-container {
    margin-bottom: 10px;
    gap: 13px;
  }
  .prosSection__bottom-part-listPolygonImg {
    width: 15px;
    height: 15px;
  }
  .prosSection__bottom-part-list-item {
    max-width: 354px;
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .prosSection__top-part {
    max-width: 300px;
    padding-top: 40px;
    padding-left: 35px;
  }
  .prosSection__top-part-title {
    max-width: 200px;
    font-size: 20px;
  }
  .prosSection__top-part-text {
    max-width: 200px;
    font-size: 11px;
  }
  .prosSection__bottom-part {
    min-height: 315px;
    overflow: hidden;
  }
  .prosSection__bottom-part-bgFigureImg {
    width: 688px;
    height: 591px;
    left: -370px;
    bottom: -320px;
  }
  .prosSection__bottom-part-BgLogoImg {
    width: 295px;
    height: 250px;
    left: 0;
  }
  .prosSection__bottom-part-list {
    left: 20px;
    bottom: 16px;
  }
  .prosSection__bottom-part-list-item-container {
    gap: 7px;
  }
  .prosSection__bottom-part-listPolygonImg {
    width: 10px;
    height: 10px;
  }
  .prosSection__bottom-part-list-item {
    max-width: 186px;
    font-size: 10px;
  }
}

