.header__phone-container {
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  transition-property: opacity;
  transition-duration: 300ms;
}

.header__phone-container:hover {
  opacity: 0.8;
}

.header__phone-text {
  margin: 0;
  color: black;
  font-weight: 400;
  font-size: 16px;
}

@media (max-width: 870px) {
  .header__phone-container {
    gap: 11px;
  }
  .header__phone-text {
    font-size: 12px;
  }
  .header__phone-ico {
    width: 17px;
    height: 17px;
  }
}

@media (max-width: 600px) {
  .header__phone-container {
    gap: 8px;
  }
  .header__phone-text {
    font-size: 8px;
  }
  .header__phone-ico {
    width: 10px;
    height: 10px;
  }
}
