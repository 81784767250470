.header__logo-container {
  display: flex;
  align-items: center;
}

@media (max-width: 870px) {
  .header__logo-img {
    width: 40px;
    height: 40px;
  }
  .header__logo-text {
    width: 120px;
    height: 18.67px;
  }
}

@media (max-width: 600px) {
  .header__logo-img {
    width: 25px;
    height: 25px;
  }
  .header__logo-text {
    width: 64.29px;
    height: 10px;
  }
}
