.footer {
  height: 520px;
  width: 100%;
  background-image: url(../../img/footer-bg-img.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.footer__logo {
  padding-top: 70px;
  padding-left: 130px;
}

.footer__card {
  width: 580px;
  height: 280px;
  background-image: url(../../img/footer-card-bg-img.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  right: 130px;
  bottom: 70px;
}

.footer__sections-links-container {
  display: flex;
  justify-content: center;
  padding-top: 16px;
  gap: 40px;
}

.footer__sections-links-container-one {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer__sections-links-container-two {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer__link {
  text-decoration: none;
  color: #34373a;
  font-weight: 500;
  font-size: 14px;
  transition-property: opacity;
  transition-duration: 300ms;
}

.footer__link:hover {
  opacity: 0.8;
}

.footer__card-contacts {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 72px;
  padding-left: 111px;
}

.footer__card-contact {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 13px;
  transition-property: opacity;
  transition-duration: 300ms;
}

.footer__card-contact:hover {
  opacity: 0.8;
}

.footer__card-contact-text {
  margin: 0;
  color: #34373a;
  font-weight: 400;
  font-size: 14px;
}

@media (max-width: 1390px) {
  .footer__logo {
    padding-left: 110px;
  }
  .footer__card {
    right: 110px;
  }
}

@media (max-width: 1270px) {
  .footer__logo {
    width: 115.38px;
    height: 50px;
    padding-top: 61px;
    padding-left: 59px;
  }
  .footer {
    height: 300px;
  }
  .footer__card {
    width: 428px;
    height: 216px;
    right: 59px;
    bottom: 20px;
  }
  .footer__sections-links-container {
    padding-top: 20px;
    gap: 52px;
  }
  .footer__link {
    font-size: 11px;
  }
  .footer__card-contacts {
    gap: 15px;
    padding-top: 27px;
    padding-left: 67px;
  }
  .footer__card-contact {
    gap: 11px;
  }
  .footer__card-contact-text {
    font-size: 11px;
  }
}

@media (max-width: 755px) {
  .footer__logo {
    width: 80px;
    height: 34.67px;
    padding-top: 40px;
    padding-left: 35px;
  }
  .footer__card {
    width: 305px;
    height: 200px;
    right: 26px;
    bottom: 10px;
  }
  .footer__sections-links-container {
    gap: 15px;
  }
  .footer__card-contacts {
    padding-left: 15px;
    gap: 10px;
    padding-top: 18px;
  }
}

@media (max-width: 374px) {
  .footer__logo {
    padding-left: 13px;
  }
  .footer__card {
    width: 278px;
    right: 14px;
  }
  .footer__card-contacts {
    padding-left: 38px;
  }
  .footer__link {
    font-size: 8px;
  }
  .footer__card-contact-text {
    font-size: 8px;
  }
  .footer__card-contact-ico {
    width: 12px;
    height: 12px;
  }
}
