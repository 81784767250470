.titleSection {
  background-image: url(../../img/titleSection-bg.png);
  min-height: 580px;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.titleSection__title {
  margin: 0;
  color: #ebedee;
  font-weight: 500;
  font-size: 80px;
  line-height: 1.1;
  padding-top: 164px;
  padding-left: 130px;
}

.titleSection__text {
  margin: 0;
  color: #ebedee;
  font-weight: 400;
  font-size: 36px;
  padding-top: 24px;
  padding-left: 130px;
}

.titleSection__img-one {
  position: absolute;
  top: 63px;
  right: 333px;
}

.titleSection__img-two {
  position: absolute;
  top: 296px;
  right: 308px;
}

.titleSection__img-three {
  position: absolute;
  top: 113px;
  right: 24px;
}

@media (max-width: 1420px) {
  .titleSection__title {
    font-size: 66px;
  }
  .titleSection__text {
    font-size: 30px;
    padding-top: 12px;
  }
}

@media (max-width: 1280px) {
  .titleSection__title {
    font-size: 68px;
  }
  .titleSection__img-one {
    width: 180px;
    height: 156.38px;
    right: 280px;
  }
  .titleSection__img-two {
    width: 240px;
    height: 209.2px;
    top: 267px;
    right: 282px;
  }
  .titleSection__img-three {
    width: 280px;
    height: 242.67px;
    top: 129px;
    right: 36px;
  }
}

@media (max-width: 1240px) {
  .titleSection {
    min-height: 340px;
  }
  .titleSection__title {
    font-size: 45px;
    padding-top: 80px;
    padding-left: 59px;
  }
  .titleSection__text {
    font-size: 18px;
    padding-top: 6px;
    padding-left: 59px;
  }
  .titleSection__img-one {
    width: 111.11px;
    height: 96.53px;
    top: 25px;
    right: 161px;
  }
  .titleSection__img-two {
    width: 148.15px;
    height: 129.14px;
    top: 151px;
    right: 163px;
  }
  .titleSection__img-three {
    width: 172.84px;
    height: 149.79px;
    top: 66px;
    right: 11px;
  }
}

@media (max-width: 767px) {
  .titleSection {
    min-height: 420px;
  }
  .titleSection__title {
    font-size: 24px;
    padding-top: 52px;
    padding-left: 35px;
  }
  .titleSection__text {
    font-size: 12px;
    padding-top: 3px;
    padding-left: 35px;
    max-width: 250px;
  }
  .titleSection__img-one {
    top: 150px;
    right: 172px;
  }
  .titleSection__img-two {
    top: 275px;
    right: 174px;
  }
  .titleSection__img-three {
    top: 192px;
    right: 22px;
  }
}

@media (max-width: 320px) {
  .titleSection__title {
    padding-left: 20px;
  }
  .titleSection__text {
    padding-left: 20px;
  }
  .titleSection__img-one {
    right: 127px;
  }
  .titleSection__img-two {
    right: 128px;
  }
  .titleSection__img-three {
    right: -23px;
  }
}
