.ourContactsSection {
  background-color: #ebedee;
  position: relative;
  overflow: hidden;
}

.ourContactsSection__container {
  max-width: 935px;
  display: flex;
  flex-direction: column;
  padding-top: 69px;
  padding-left: 130px;
  padding-bottom: 34px;
}

.ourContactsSection__title {
  margin: 0;
  max-width: 455px;
  color: #34373a;
  font-weight: 500;
  font-size: 64px;
  line-height: 1.1;
}

.ourContactsSection__text {
  margin: 0;
  max-width: 480px;
  color: #34373a;
  font-weight: 400;
  font-size: 24px;
  align-self: flex-end;
  padding-bottom: 20px;
}

.ourContactsSection__text:last-of-type {
  padding-bottom: 0;
}

.ourContactsSection__contacts-containers {
  padding-left: 130px;
  display: flex;
  flex-direction: column;
  gap: 43px;
  padding-bottom: 81px;
}

.ourContactsSection__contacts-mail {
  display: flex;
  align-items: center;
  gap: 20px;
  text-decoration: none;
  transition-property: opacity;
  transition-duration: 300ms;
}

.ourContactsSection__contacts-mail:hover {
  opacity: 0.8;
}

.ourContactsSection__contacts-mail-text {
  margin: 0;
  color: #34373a;
  font-weight: 500;
  font-size: 32px;
}

.ourContactsSection__contacts-phone {
  display: flex;
  align-items: center;
  gap: 20px;
  text-decoration: none;
  transition-property: opacity;
  transition-duration: 300ms;
}

.ourContactsSection__contacts-phone:hover {
  opacity: 0.8;
}

.ourContactsSection__contacts-phone-text {
  margin: 0;
  color: #34373a;
  font-weight: 500;
  font-size: 32px;
}

.ourContactsSection__contacts-adress {
  display: flex;
  align-items: center;
  gap: 20px;
  text-decoration: none;
  transition-property: opacity;
  transition-duration: 300ms;
}

.ourContactsSection__contacts-adress:hover {
  opacity: 0.8;
}

.ourContactsSection__contacts-adress-text {
  margin: 0;
  color: #34373a;
  font-weight: 500;
  font-size: 32px;
}

.ourContactsSection__map {
  height: 500px;
  width: 100%;
  background-image: url(../../img/ourContactsSection-map-img.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  transition-property: opacity;
  transition-duration: 300ms;
}

.ourContactsSection__map:hover {
  opacity: 0.8;
}

.ourContactsSectio__bg-figure {
  position: absolute;
  right: 0;
  top: 0;
}

@media (max-width: 1390px) {
  .ourContactsSection__container {
    padding-left: 110px;
  }
  .ourContactsSection__contacts-containers {
    padding-left: 110px;
  }
  .ourContactsSectio__bg-figure {
    right: -167px;
  }
}

@media (max-width: 1270px) {
  .ourContactsSection__container {
    max-width: 650px;
    padding-top: 60px;
    padding-left: 59px;
    padding-bottom: 17px;
  }
  .ourContactsSection__title {
    max-width: 210px;
    font-size: 36px;
  }
  .ourContactsSection__text {
    max-width: 373px;
    font-size: 16px;
    padding-bottom: 10px;
  }
  .ourContactsSection__contacts-containers {
    padding-left: 59px;
    gap: 15px;
    padding-bottom: 26px;
  }
  .ourContactsSection__contacts-mail {
    gap: 16px;
  }
  .ourContactsSection__contacts-mail-img {
    width: 20px;
    height: 20px;
  }
  .ourContactsSection__contacts-mail-text {
    font-size: 15px;
  }
  .ourContactsSection__contacts-phone {
    gap: 16px;
  }
  .ourContactsSection__contacts-phone-img {
    width: 20px;
    height: 20px;
  }
  .ourContactsSection__contacts-phone-text {
    font-size: 15px;
  }
  .ourContactsSection__contacts-adress {
    gap: 16px;
  }
  .ourContactsSection__contacts-adress-img {
    width: 30px;
    height: 30px;
  }
  .ourContactsSection__contacts-adress-text {
    font-size: 15px;
  }
  .ourContactsSection__map {
    height: 275px;
  }
  .ourContactsSectio__bg-figure {
    display: none;
  }
}

@media (max-width: 755px) {
  .ourContactsSection__container {
    max-width: 305px;
    padding-top: 40px;
    padding-left: 35px;
    padding-bottom: 10px;
  }
  .ourContactsSection__title {
    max-width: 105px;
    font-size: 20px;
  }
  .ourContactsSection__text {
    max-width: 200px;
    font-size: 10px;
    padding-bottom: 10px;
  }
  .ourContactsSection__contacts-containers {
    padding-left: 35px;
    gap: 15px;
    padding-bottom: 15px;
  }
  .ourContactsSection__contacts-mail {
    gap: 7px;
  }
  .ourContactsSection__contacts-mail-img {
    width: 15px;
    height: 15px;
  }
  .ourContactsSection__contacts-mail-text {
    font-size: 10px;
  }
  .ourContactsSection__contacts-phone {
    gap: 7px;
  }
  .ourContactsSection__contacts-phone-img {
    width: 10px;
    height: 10px;
  }
  .ourContactsSection__contacts-phone-text {
    font-size: 10px;
  }
  .ourContactsSection__contacts-adress {
    gap: 7px;
  }
  .ourContactsSection__contacts-adress-img {
    width: 15px;
    height: 15px;
  }
  .ourContactsSection__contacts-adress-text {
    font-size: 10px;
  }
  .ourContactsSection__map {
    height: 179px;
  }
  .ourContactsSectio__bg-figure {
    display: block;
    width: 298px;
    height: 298px;
  }
  .ourContactsSectio__bg-figure {
    right: -232px;
  }
}

@media (max-width: 374px) {
  .ourContactsSection__container {
    padding-left: 20px;
  }
  .ourContactsSection__contacts-containers {
    padding-left: 20px;
  }
  .ourContactsSectio__bg-figure {
    right: -260px;
  }
}
