.weOfferSection {
  background-color: #34373a;
  position: relative;
  z-index: -2;
  overflow: hidden;
}

.weOfferSection__container {
  max-width: 880px;
  display: flex;
  flex-direction: column;
  padding-top: 75px;
  padding-left: 130px;
}

.weOfferSection__title {
  margin: 0;
  max-width: 380px;
  color: #ebedee;
  font-weight: 500;
  font-size: 64px;
  line-height: 1.1;
}

.weOfferSection__text {
  margin: 0;
  max-width: 380px;
  color: #ebedee;
  font-weight: 400;
  font-size: 24px;
  align-self: flex-end;
}

.weOfferSection__polygon {
  position: absolute;
  top: 144px;
  right: 0;
  z-index: -1;
}

.weOfferSection__cards {
  padding-top: 23px;
  display: flex;
  gap: 20px;
  justify-content: center;
  padding-bottom: 69px;
}

.weOfferSection__card {
  width: 380px;
  height: 540px;
  background-color: #ebedee;
}

.weOfferSection__card-all-content {
  padding-top: 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
}

.weOfferSection__card-text-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.weOfferSection__card-title {
  margin: 0;
  max-width: 315px;
  color: #34373a;
  font-weight: 500;
  font-size: 32px;
  text-transform: uppercase;
}

.weOfferSection__card-text {
  margin: 0;
  max-width: 325px;
  color: #34373a;
  font-weight: 400;
  font-size: 18px;
}

@media (max-width: 1390px) {
  .weOfferSection__container {
    max-width: 830px;
    padding-left: 110px;
  }
  .weOfferSection__cards {
    padding-bottom: 29px;
  }
  .weOfferSection__card {
    width: 340px;
    height: 580px;
  }
  .weOfferSection__card-img {
    width: 300px;
    height: 180px;
  }
  .weOfferSection__card-title {
    max-width: 309px;
  }
  .weOfferSection__card-text {
    max-width: 300px;
  }
  .weOfferSection__polygon {
    right: -159px;
  }
}

@media (max-width: 1270px) {
  .weOfferSection__container {
    max-width: 465px;
    padding-top: 60px;
    padding-left: 59px;
  }
  .weOfferSection__title {
    max-width: 245px;
    font-size: 36px;
  }
  .weOfferSection__text {
    max-width: 245px;
    font-size: 16px;
  }
  .weOfferSection__cards {
    padding-bottom: 31.76px;
    padding-top: 63px;
    gap: 10px;
  }
  .weOfferSection__card {
    width: 210px;
    height: 358.24px;
  }
  .weOfferSection__card-img {
    width: 185.29px;
    height: 111.18px;
  }
  .weOfferSection__card-all-content {
    padding-top: 10.5px;
    gap: 37px;
  }
  .weOfferSection__card-text-content {
    gap: 6px;
  }
  .weOfferSection__card-title {
    max-width: 190px;
    font-size: 20px;
  }
  .weOfferSection__card-text {
    max-width: 185px;
    font-size: 11px;
  }
  .weOfferSection__polygon {
    width: 446.93px;
    height: 387px;
    right: -122px;
    top: 149px;
  }
}

@media (max-width: 755px) {
  .weOfferSection__container {
    max-width: 305px;
    padding-top: 40px;
    padding-left: 35px;
  }
  .weOfferSection__title {
    max-width: 128px;
    font-size: 20px;
  }
  .weOfferSection__text {
    max-width: 200px;
    font-size: 10px;
  }
  .weOfferSection__cards {
    flex-wrap: wrap;
    padding-top: 20px;
    padding-bottom: 14px;
    gap: 20px;
  }
}

@media (max-width: 456px) {
  .weOfferSection__polygon {
    top: 516px;
  }
}

@media (max-width: 374px) {
  .weOfferSection__polygon {
    right: -150px;
  }
}
