.whereUsedSection {
  background-color: #34373a;
}

.whereUsedSection__container {
  max-width: 1180px;
  display: flex;
  flex-direction: column;
  padding-top: 69px;
  padding-left: 130px;
}

.whereUsedSection__title {
  margin: 0;
  max-width: 500px;
  color: #ebedee;
  font-weight: 500;
  font-size: 64px;
  line-height: 1.1;
}

.whereUsedSection__text {
  margin: 0;
  max-width: 680px;
  color: #ebedee;
  font-weight: 400;
  font-size: 24px;
  align-self: flex-end;
}

.whereUsedSection__cards-container {
  display: flex;
  justify-content: center;
  padding-top: 90px;
  padding-bottom: 145px;
}

.whereUsedSection__cards {
  display: flex;
  flex-wrap: wrap;
  max-width: 1180px;
  gap: 20px;
}

.whereUsedSection__card {
  width: 380px;
  height: 480px;
  background-image: url(../../img/whereUsedSection-card-bg-img.png);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.whereUsedSection__card-img {
  position: absolute;
  top: 0;
  left: 0;
}

.whereUsedSection__card-img-broken {
  height: 348px;
  width: 380px;
  position: absolute;
  top: 0;
  left: 0;
}

.whereUsedSection__card-ico {
  position: absolute;
  top: 25%;
  left: 35%;
}

.whereUsedSection__card-texts {
  margin: 0;
  max-width: 246px;
  color: #34373a;
  font-weight: 500;
  font-size: 24px;
  text-transform: uppercase;
  text-align: right;
  position: absolute;
  bottom: 41px;
  right: 24px;
}

@media (max-width: 1390px) {
  .whereUsedSection__container {
    padding-left: 110px;
  }
  .whereUsedSection__cards-container {
    padding-bottom: 100px;
  }
  .whereUsedSection__cards {
    max-width: 1060px;
  }
  .whereUsedSection__card {
    width: 340px;
    height: 480px;
  }
  .whereUsedSection__card-img {
    height: 348px;
    width: 340px;
  }
  .whereUsedSection__card-img-broken {
    height: 348px;
    width: 340px;
  }
  .whereUsedSection__card-texts {
    bottom: 35px;
    right: 15px;
  }
}

@media (max-width: 1270px) {
  .whereUsedSection__container {
    max-width: 648px;
    padding-top: 60px;
    padding-left: 60px;
  }
  .whereUsedSection__title {
    max-width: 319px;
    font-size: 36px;
  }
  .whereUsedSection__text {
    max-width: 430px;
    font-size: 16px;
  }
  .whereUsedSection__cards-container {
    padding-top: 17px;
    padding-bottom: 28px;
  }
  .whereUsedSection__cards {
    max-width: 650px;
    gap: 10px;
  }
  .whereUsedSection__card {
    width: 210px;
    height: 296px;
  }
  .whereUsedSection__card-img {
    height: 214.94px;
    width: 210px;
  }
  .whereUsedSection__card-img-broken {
    height: 214.94px;
    width: 210px;
  }
  .whereUsedSection__card-ico {
    width: 72px;
    height: 61px;
  }
  .whereUsedSection__card-texts {
    max-width: 163px;
    font-size: 15px;
    bottom: 20.47px;
    right: 9px;
  }
}

@media (max-width: 755px) {
  .whereUsedSection__container {
    max-width: 305px;
    padding-top: 40px;
    padding-left: 35px;
  }
  .whereUsedSection__title {
    max-width: 128px;
    font-size: 20px;
  }
  .whereUsedSection__text {
    max-width: 200px;
    font-size: 10px;
  }
  .whereUsedSection__cards {
    max-width: 210px;
  }
}

@media (max-width: 374px) {
  .whereUsedSection__text {
    max-width: 185px;
  }
}
