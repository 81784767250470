.purposeSection {
  background-color: #34373a;
  position: relative;
  z-index: -2;
  overflow: hidden;
}

.purposeSection__container {
  max-width: 880px;
  display: flex;
  flex-direction: column;
  padding-top: 69px;
  padding-left: 130px;
}

.purposeSection__title {
  margin: 0;
  max-width: 380px;
  color: #ebedee;
  font-weight: 500;
  font-size: 64px;
  line-height: 1.1;
}

.purposeSection__text {
  margin: 0;
  max-width: 380px;
  color: #ebedee;
  font-weight: 400;
  font-size: 24px;
  align-self: flex-end;
}

.purposeSection__text-frames-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  max-width: 800px;
  margin-top: 63px;
  padding-left: 130px;
  padding-bottom: 120px;
}

.purposeSection__text-frame-container {
  background-image: url(../../img/frame.png);
  height: 80px;
  width: 380px;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.purposeSection__text-frame-big-poligon {
  background-image: url(../../img/big-polygon.png);
  position: absolute;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: contain;
  top: -10px;
  left: 70px;
}

.purposeSection__text-frame-small-poligon {
  background-image: url(../../img/small-polygon.png);
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  top: -7px;
  left: 163px;
}

.purposeSection__text-frame-container-two {
  background-image: url(../../img/frame-two.png);
  height: 80px;
  width: 380px;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.purposeSection__text-frame-big-poligon-two {
  background-image: url(../../img/big-polygon.png);
  position: absolute;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: contain;
  top: -10px;
  left: 280px;
}

.purposeSection__text-frame-small-poligon-two {
  background-image: url(../../img/small-polygon.png);
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  top: -7px;
  left: 191px;
}

.purposeSection__text-frame {
  margin: 0;
  max-width: 304px;
  color: #ebedee;
  font-weight: 400;
  font-size: 18px;
}

.purposeSection__img {
  position: absolute;
  top: 70px;
  right: 0;
}

.purposeSection__polygon {
  position: absolute;
  top: 131px;
  right: 0;
  z-index: -1;
}

@media (max-width: 1390px) {
  .purposeSection__container {
    padding-left: 110px;
    max-width: 830px;
  }
  .purposeSection__text-frames-container {
    padding-left: 110px;
  }
  .purposeSection__text-frame-container {
    width: 340px;
  }
  .purposeSection__text-frame-container-two {
    width: 340px;
  }
  .purposeSection__text-frame-small-poligon {
    left: 142px;
  }
  .purposeSection__text-frame-big-poligon-two {
    left: 253px;
  }
  .purposeSection__text-frame-small-poligon-two {
    left: 177px;
  }
  .purposeSection__img {
    right: -50px;
  }
  .purposeSection__polygon {
    right: -159px;
  }
}

@media (max-width: 1270px) {
  .purposeSection__container {
    padding-top: 59px;
    padding-left: 59px;
    max-width: 465px;
  }
  .purposeSection__title {
    max-width: 180px;
    font-size: 36px;
  }
  .purposeSection__text {
    max-width: 245px;
    font-size: 16px;
  }
  .purposeSection__text-frames-container {
    padding-left: 59px;
    padding-bottom: 15px;
    max-width: 505px;
  }
  .purposeSection__text-frame-container {
    width: 240px;
    height: 53px;
  }
  .purposeSection__text-frame-container-two {
    width: 240px;
    height: 53px;
  }
  .purposeSection__text-frame-big-poligon {
    width: 25px;
    height: 25px;
    left: 50px;
  }
  .purposeSection__text-frame-small-poligon {
    width: 17px;
    height: 17px;
    left: 98px;
  }
  .purposeSection__text-frame-big-poligon-two {
    width: 25px;
    height: 25px;
    left: 165px;
  }
  .purposeSection__text-frame-small-poligon-two {
    width: 17px;
    height: 17px;
    left: 125px;
  }
  .purposeSection__text-frame {
    max-width: 214px;
    font-size: 12px;
  }
  .purposeSection__img {
    width: 300px;
    height: 425px;
    right: -119px;
    top: 60px;
  }
  .purposeSection__polygon {
    width: 446.93px;
    height: 387px;
    right: -122px;
    top: 149px;
  }
}

@media (max-width: 755px) {
  .purposeSection__container {
    padding-top: 40px;
    padding-left: 35px;
    max-width: 305px;
  }
  .purposeSection__title {
    max-width: 105px;
    font-size: 20px;
  }
  .purposeSection__text {
    max-width: 200px;
    font-size: 10px;
  }
  .purposeSection__text-frames-container {
    padding-left: 35px;
    padding-bottom: 23.78px;
    max-width: 200px;
    gap: 28px;
    margin-top: 15px;
  }
  .purposeSection__text-frame-container {
    width: 200px;
    height: 45px;
  }
  .purposeSection__text-frame-container-two {
    width: 200px;
    height: 45px;
  }
  .purposeSection__text-frame-big-poligon {
    width: 20px;
    height: 20px;
    top: -8px;
    left: 42px;
  }
  .purposeSection__text-frame-small-poligon {
    width: 14px;
    height: 14px;
    left: 82px;
    top: -6px;
  }
  .purposeSection__text-frame-big-poligon-two {
    width: 20px;
    height: 20px;
    top: -8px;
    left: 138px;
  }
  .purposeSection__text-frame-small-poligon-two {
    width: 14px;
    height: 14px;
    left: 104px;
    top: -6px;
  }
  .purposeSection__text-frame {
    max-width: 174px;
    font-size: 10px;
  }
  .purposeSection__img {
    width: 222px;
    height: 264px;
    right: -94px;
    top: 156px;
  }
  .purposeSection__polygon {
    width: 446.93px;
    height: 325px;
    right: -244px;
    top: 118px;
  }
}

@media (max-width: 374px) {
  .purposeSection__container {
    padding-left: 20px;
  }
  .purposeSection__text-frames-container {
    padding-left: 20px;
  }
  .purposeSection__img {
    right: -130px;
  }
  .purposeSection__polygon {
    right: -287px;
  }
}
