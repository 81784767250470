.stagesSection {
  background-color: #ebedee;
}

.stagesSection__container {
  max-width: 980px;
  display: flex;
  flex-direction: column;
  padding-top: 69px;
  padding-left: 130px;
}

.stagesSection__title {
  margin: 0;
  max-width: 500px;
  color: #34373a;
  font-weight: 500;
  font-size: 64px;
  line-height: 1.1;
}

.stagesSection__text {
  margin: 0;
  max-width: 480px;
  color: #34373a;
  font-weight: 400;
  font-size: 24px;
  align-self: flex-end;
}

.stagesSection__scheme {
  position: relative;
  margin-top: 42px;
  padding-bottom: 75px;
}

.stagesSection__scheme-part-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 38px;
}

.stagesSection__scheme-part-container-eight {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 19px;
}

.stagesSection__scheme-part-container:last-of-type {
  margin-bottom: 0;
}

.stagesSection__scheme-part-content {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 948px;
  position: relative;
}

.stagesSection__scheme-part-content-revers {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 948px;
  position: relative;
}

.stagesSection__scheme-part-content-revers-short {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 792px;
  position: relative;
}

.stagesSection__scheme-part-content-revers-short-two {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 905px;
  position: relative;
}

.stagesSection__scheme-part-text {
  margin: 0;
  max-width: 474px;
  color: #34373a;
  font-weight: 500;
  font-size: 32px;
  text-align: right;
}

.stagesSection__scheme-part-text-revers {
  margin: 0;
  max-width: 455px;
  color: #34373a;
  font-weight: 500;
  font-size: 32px;
  text-align: left;
}

.stagesSection__scheme-bg-container {
  position: absolute;
  top: 0;
  left: 50%;
}

.stagesSection__scheme-bg-img {
  background-image: url(../../img/stagesSection-scheme-part.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 18px;
  height: 100px;
}

.stagesSection__scheme-bg-img-end {
  background-image: url(../../img/stagesSection-scheme-part-end.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 18px;
  height: 15px;
}

.stagesSection__scheme-part-number-one {
  background-image: url(../../img/stagesSection-number-one.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-size: contain;
  width: 172px;
  height: 165px;
  position: absolute;
  left: 0;
  top: -50%;
}

.stagesSection__scheme-part-number-two-revers {
  background-image: url(../../img/stagesSection-number-two.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-size: contain;
  width: 196px;
  height: 165px;
  position: absolute;
  right: 0;
  top: -50%;
}

.stagesSection__scheme-part-number-three {
  background-image: url(../../img/stagesSection-number-three.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-size: contain;
  width: 197px;
  height: 165px;
  position: absolute;
  left: 0;
  top: -50%;
}

.stagesSection__scheme-part-number-four-revers {
  background-image: url(../../img/stagesSection-number-four.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-size: contain;
  width: 201px;
  height: 165px;
  position: absolute;
  right: -81px;
  top: -94%;
}

.stagesSection__scheme-part-number-five {
  background-image: url(../../img/stagesSection-number-five.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-size: contain;
  width: 195px;
  height: 165px;
  position: absolute;
  left: 0;
  top: -50%;
}

.stagesSection__scheme-part-number-six-revers {
  background-image: url(../../img/stagesSection-number-six.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-size: contain;
  width: 196px;
  height: 165px;
  position: absolute;
  right: -23px;
  top: -94%;
}

.stagesSection__scheme-part-number-seven {
  background-image: url(../../img/stagesSection-number-seven.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-size: contain;
  width: 184px;
  height: 165px;
  position: absolute;
  left: 0;
  top: -50%;
}

.stagesSection__scheme-part-number-eight-revers {
  background-image: url(../../img/stagesSection-number-eight.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-size: contain;
  width: 201px;
  height: 165px;
  position: absolute;
  right: 0;
  top: -50%;
}

.stagesSection__scheme-part-number-nine {
  background-image: url(../../img/stagesSection-number-nine.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-size: contain;
  width: 195px;
  height: 165px;
  position: absolute;
  left: 0;
  top: -32%;
}

@media (max-width: 1390px) {
  .stagesSection__container {
    padding-left: 110px;
  }
}

@media (max-width: 1270px) {
  .stagesSection__container {
    max-width: 650px;
    padding-top: 60px;
    padding-left: 59px;
  }
  .stagesSection__title {
    max-width: 320px;
    font-size: 36px;
  }
  .stagesSection__text {
    max-width: 430px;
    font-size: 16px;
  }
  .stagesSection__scheme {
    margin-top: 21px;
    padding-bottom: 30px;
  }
  .stagesSection__scheme-bg-img {
    height: 76px;
  }
  .stagesSection__scheme-part-text {
    max-width: 315px;
    font-size: 20px;
  }
  .stagesSection__scheme-part-text-revers {
    max-width: 304px;
    font-size: 20px;
  }
  .stagesSection__scheme-part-number-one {
    width: 115px;
    height: 110px;
  }
  .stagesSection__scheme-part-number-two-revers {
    width: 131px;
    height: 110px;
    top: -75%;
  }
  .stagesSection__scheme-part-number-three {
    width: 132px;
    height: 110px;
  }
  .stagesSection__scheme-part-number-four-revers {
    width: 134px;
    height: 110px;
    top: -135%;
  }
  .stagesSection__scheme-part-number-five {
    width: 130px;
    height: 110px;
  }
  .stagesSection__scheme-part-number-six-revers {
    width: 131px;
    height: 110px;
    top: -135%;
    right: -36px;
  }
  .stagesSection__scheme-part-number-seven {
    width: 123px;
    height: 110px;
  }
  .stagesSection__scheme-part-number-eight-revers {
    width: 134px;
    height: 110px;
    top: -75%;
  }
  .stagesSection__scheme-part-number-nine {
    width: 130px;
    height: 110px;
    top: -76%;
  }
  .stagesSection__scheme-part-content {
    width: 629px;
  }
  .stagesSection__scheme-part-content-revers {
    width: 640px;
  }
  .stagesSection__scheme-part-content-revers-short {
    width: 493px;
  }
  .stagesSection__scheme-part-content-revers-short-two {
    width: 570px;
  }
  .stagesSection__scheme-part-container-eight {
    margin-bottom: 30px;
  }
}

@media (max-width: 755px) {
  .stagesSection__container {
    max-width: 305px;
    padding-top: 40px;
    padding-left: 35px;
  }
  .stagesSection__title {
    max-width: 200px;
    font-size: 20px;
  }
  .stagesSection__text {
    max-width: 200px;
    font-size: 10px;
  }
  .stagesSection__scheme-bg-img {
    height: 54.35px;
  }
  .stagesSection__scheme-part-text {
    max-width: 180px;
    font-size: 12px;
  }
  .stagesSection__scheme-part-text-revers {
    max-width: 180px;
    font-size: 12px;
    text-align: end;
  }
  .stagesSection__scheme-part-number-one {
    width: 61px;
    height: 55px;
    left: 200px;
  }
  .stagesSection__scheme-part-number-two-revers {
    width: 66px;
    height: 55px;
    right: 94px;
    top: -47%;
  }
  .stagesSection__scheme-part-number-three {
    width: 66px;
    height: 55px;
    left: 200px;
  }
  .stagesSection__scheme-part-number-four-revers {
    width: 67px;
    height: 55px;
    right: 55px;
    top: -158%;
  }
  .stagesSection__scheme-part-number-five {
    width: 65px;
    height: 55px;
    left: 200px;
  }
  .stagesSection__scheme-part-number-six-revers {
    width: 66px;
    height: 55px;
    right: 77px;
    top: -153%;
  }
  .stagesSection__scheme-part-number-seven {
    width: 62px;
    height: 55px;
    left: 200px;
  }
  .stagesSection__scheme-part-number-eight-revers {
    width: 67px;
    height: 55px;
    right: 94px;
    top: -63%;
  }
  .stagesSection__scheme-part-number-nine {
    width: 65px;
    height: 55px;
    left: 200px;
  }
  .stagesSection__scheme-part-content {
    width: 360px;
  }
  .stagesSection__scheme-part-content-revers {
    width: 360px;
    justify-content: start;
  }
  .stagesSection__scheme-part-content-revers-short {
    width: 285px;
    justify-content: start;
  }
  .stagesSection__scheme-part-content-revers-short-two {
    width: 327px;
    justify-content: start;
  }
  .stagesSection__scheme-part-container {
    margin-bottom: 34px;
  }
  .stagesSection__scheme-part-container-eight {
    margin-bottom: 30px;
  }
}

@media (max-width: 374px) {
  .stagesSection__scheme-part-container {
    margin-bottom: 26px;
  }
  .stagesSection__scheme-part-content {
    width: 300px;
  }
  .stagesSection__scheme-part-text {
    max-width: 150px;
  }
  .stagesSection__scheme-part-text-revers {
    max-width: 145px;
  }
  .stagesSection__scheme-part-number-one {
    left: 172px;
    top: -7px;
  }
  .stagesSection__scheme-part-number-two-revers {
    right: 72px;
    top: -21%;
  }
  .stagesSection__scheme-part-number-three {
    left: 172px;
    top: -20px;
  }
  .stagesSection__scheme-part-number-four-revers {
    right: 55px;
    top: -158%;
  }
  .stagesSection__scheme-part-number-five {
    left: 172px;
    top: -7px;
  }
  .stagesSection__scheme-part-number-six-revers {
    right: 72px;
    top: -38%;
  }
  .stagesSection__scheme-part-number-seven {
    left: 172px;
    top: -7px;
  }
  .stagesSection__scheme-part-number-eight-revers {
    right: 72px;
    top: -29%;
  }
  .stagesSection__scheme-part-number-nine {
    left: 172px;
    top: -7px;
  }
}
