.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ebedee;
  min-height: 70px;
  padding: 0 130px;
}

.header-container {
  display: flex;
  align-items: center;
  gap: 36px;
}

@media (max-width: 1280px) {
  .header {
    padding-left: 110px;
    padding-right: 110px;
  }
  .header-container {
    gap: 15px;
  }
}

@media (max-width: 870px) {
  .header {
    min-height: 60px;
    padding-left: 59px;
    padding-right: 59px;
  }
  .header-container {
    gap: 35px;
  }
}

@media (max-width: 600px) {
  .header {
    min-height: 40px;
    padding-left: 35px;
    padding-right: 35px;
  }
  .header-container {
    flex-direction: column;
    gap: 4px;
    align-items: start;
  }
}

@media (max-width: 320px) {
  .header {
    padding-left: 19px;
    padding-right: 20px;
  }
}
